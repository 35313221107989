/**
 * Customizable branded page wrapper for public pages, based on the company's custom branding
 * - Uses the {@link ThemeWrapper} to override the theme color variables with the custom values
 * - shows a header with the company's logo or name
 * - displays the children below the header, inside the theme wrapper so that all custom colors will be applied by the updated variables
 *
 * @packageDocumentation
 */
import React from 'react';

import { BrandedPageProps } from './BrandedPage.types';
import ThemeWrapper from 'components/common/ThemeWrapper';

const BrandedPage: React.FC<BrandedPageProps> = ({
  children,
  logo,
  name,
  stickyHeader = true,
  bgPlain = false,
  hideHeader = false,
  ...props
}) => {
  return (
    <ThemeWrapper {...props} data-testid='BrandedPage'>
      <div className='min-h-screen bg-white overflow-auto'>
        {!hideHeader && (
          <header
            className={`h-16 z-500 bg-white shadow-md text-primary flex items-center justify-center left-0 right-0 w-full ${
              stickyHeader ? 'fixed' : ''
            }`}
          >
            {logo ? (
              <img src={logo} alt={name || 'logo'} className='h-12' />
            ) : (
              <h1 className='text-4xl capitalize'>{name || ''}</h1>
            )}
          </header>
        )}
        <main
          className={`min-h-screen flex justify-center px-2 sm:px-0 bg-gray-100 ${
            stickyHeader ? 'pt-28' : 'pt-12'
          } `}
        >
          {/* <main
          className={`min-h-screen flex justify-center px-2 sm:px-0 ${
            !bgPlain && 'bg-primary public__page--bg'
          } ${stickyHeader ? 'pt-28' : 'pt-12'} `}
        > */}
          {children}
        </main>
      </div>
    </ThemeWrapper>
  );
};

export default BrandedPage;
