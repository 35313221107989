// BrandedPageHead
/**
 * Customizable html head tag for all custom public pages
 *
 * @packageDocumentation
 */
import React from "react";
import Head from "next/head";

import { BrandedPageHeadProps } from "./BrandedPageHead.types";

/**
 * @returns - customized head tag element
 */

const BrandedPageHead: React.FC<BrandedPageHeadProps> = ({
  pageData,
  pageName,
}) => {
  return (
    <div data-testid="BrandedPageHead" className="">
      <Head>
        {/* Set the title and meta description for SEO */}
        <title>{pageData.metaTitle ? pageData.metaTitle : `${pageName}`}</title>
        {pageData.metaDescription && (
          <meta name="description" content={pageData.metaDescription} />
        )}
      </Head>
    </div>
  );
};

export default BrandedPageHead;
