// ThemeWrapper
/**
 * A wrapper that changes theme colors of all child components to the ones provided as props
 *
 * Theme colors are managed using color variables on the root in the Tailwind base layer
 * By changing the values of the color variables, all child components can be given a new theme
 *
 *
 * @packageDocumentation
 */
import React, { MutableRefObject, useEffect } from 'react';

import { ThemeWrapperProps } from './ThemeWrapper.types';

const ThemeWrapper: React.FC<ThemeWrapperProps> = ({
  colorPrimary,
  children,
}) => {
  const componentRef: MutableRefObject<HTMLDivElement> = React.useRef();

  useEffect(() => {
    if (colorPrimary) {
      componentRef.current.style.setProperty(`--color-primary`, colorPrimary);
    }
  }, [colorPrimary]);

  return (
    <div
      data-testid='ThemeWrapper'
      ref={(node) => (componentRef.current = node)}
    >
      {children}
    </div>
  );
};

export default ThemeWrapper;
